export const formatDate = (dateString) => {
  const [year, month, day] = dateString.split("-");
  return `${day}/${month}/${year}`;
};

export const formatPhoneToNumbers = (phone) =>
  phone
    .toString()
    .split("(")
    .join("")
    .split(")")
    .join("")
    .split(" ")
    .join("")
    .split("-")
    .join("");

