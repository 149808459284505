import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import newBaseApi from "../../Services/newBaseApi";
import "./css/Anuncio.css";
import Tab from "./tabs";
import DefaultUser from "../../Assets/defaultuser.jpeg";
import Footer from "../../components/Footer/Footer";

export default function Anuncio() {
  const params = useParams();

  const [data, setData] = useState([]);

  useEffect(() => {
    fixLoad();
    async function loadExibir() {
      try {
        const response = await newBaseApi.get(
          `/anunciante/public-data/${params.id}`
        );
        setData(response.data);
      } catch (error) {
        console.error("Erro ao carregar dados do anunciante:", error);
      }
    }

    loadExibir();
  }, [params.idAnunciante]);

  const fixLoad = () => {
    document.querySelector("html").style = "scroll-behavior: unset;";
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  };

  return (
    <>
      <div className="container-anuncio">
        <div className="main-anuncio">
          <div className="imagem-anuncio">
            {data.logo ? (
              <img src={data.logo} alt="Logo do Anunciante" />
            ) : (
              <img src={DefaultUser} alt="Logo Padrão" />
            )}
          </div>
          <h1>{data.nome}</h1>
          <p>{data.descricao || "Descrição não disponível."}</p>
        </div>
        <div className="container-tabs">
          <Tab data={data} />
        </div>
      </div>
      <Footer />
    </>
  );
}
